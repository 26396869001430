
// Fonts
@import url('https://fonts.googleapis.com/css?family=Comfortaa');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  position: relative;
  font-family: 'Comfortaa', cursive;
  background-color: rgba(204,255,204, 0.2);
}

.back-top{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  text-align: right;

}

.btn{
    border-radius: 0;
}

.navbar-collapse{
    margin-top: 15px;
}

.navbar-collapse ul{
    margin-top: -10px;
}

.navbar-toggle{
  margin-top: 20px;
}

.map{
    width: 100%;
    height: 400px;
}

.yasza{
  text-decoration: none;
  color: #CB1B69;
}

a.yasza:hover,a.yasza:active,a.yasza:focus{
  text-decoration: none;
  color: #CB1B69;
}

.navbar{
  min-height: 80px;
}

.navbar-custom {
    background-color: rgba(51,51,51, 0.75) !important;
    border-color: rgba(51,51,51, 0.75) !important;
}

.navbar-inverse .navbar-nav > li > a {
    color: $brand-primary;
}

.hero-img{
    position: absolute;
    width: 100%;
}

.parent {
  position: relative;
  height: 100%;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exp-overlay {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 60%;
}

.underline{
  border-bottom-style: solid;
  border-bottom-color: $brand-primary;
  border-bottom-width: 2px;
  padding-bottom: 5px;
  display: inline-block;
}

.footer{
  border-bottom-style: solid;
  border-bottom-color: $brand-primary;
  border-bottom-width: 2px;
  border-top-style: solid;
  border-top-color: $brand-primary;
  border-top-width: 2px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.product-overlay {
  top:0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-desc{
    background-color: rgba(255,255,255,0.7);
    padding: 15px;
    height: 100%;
    display: block;
}

.bg-desc-right{
    background-color: rgb(255,255,255);
    padding: 15px;
    height: 100%;
    display: block;
    padding-right: 7%;
}

.bg-desc-left{
    background-color: rgb(255,255,255);
    padding: 15px;
    height: 100%;
    display: block;
    padding-left: 7%;
}

.price-bg{
  background-color: $brand-primary;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}

.title-border{
  border-color: $brand-primary;
  border-style: solid;
  border-width: 1.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}

.product-border{
  border-color: $brand-primary;
  border-style: solid;
  border-width: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  color: #fff;
  background-color: transparent;
}

button.product-border:hover{
  background-color: $brand-primary;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

button.product-border:active{
  background-color: $brand-primary;
  text-decoration: none;
  color: #fff;
}

button.product-border:focus{
  background-color: $brand-primary;
  text-decoration: none;
  color: #fff;
}

.product-border-active{
  background-color: $brand-primary;
  text-decoration: none;
  color: #fff;
}

.grafis-treatment{
  overflow:hidden;
  width: 100%;
  position: relative;
}

.wrap-img{
  max-height: 500px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.grafis-exp{
  max-height: 500px;
  overflow:hidden;
  width: 100%;
  position: relative;
}

.brand-color{
  color: $brand-primary;
}

.secondary-color{
  color: $brand-secondary;
}

.padding-gallery{
  padding-bottom: 30px;
  max-height: 240px;
  overflow: hidden;
  margin-bottom: 30px;
}

.padding-product{
  padding-bottom: 30px;
  height: 350px;
  width: 350px;
  overflow: hidden;
  position: relative;
}

.product-title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $brand-primary;
  color: #fff;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.lSSlideOuter .lSPager.lSpg > li a {
    background-color: rga(51,51,51);
}

.lSSlideOuter .lSPager.lSpg > li:hover a, .lSSlideOuter .lSPager.lSpg > li.active a {
    background-color: $brand-primary !important;
}

.carousel-control.left, .carousel-control.right {
   background-image:none !important;
   filter:none !important;
}

.head-section{
    width: 100%;
    max-height: 550px;
    overflow: hidden;
    position: relative;
}

.head-section-home{
    width: 100%;
    max-height: 768px;
    overflow: hidden;
    position: relative;
}

.head-title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  display: inline-block;
  text-align: center;
}

.head-title-home{
  position: absolute;
  top: 65%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 370px;
  display: inline-block;
  text-align: left;
  background-color: rgba(0,0,0,0.65);
  color: #fff;
  padding: 15px;
}

.head-title-border{
  background-color: rgba(255,255,255, 0.86);
  padding: 20px;
}

.title-content{
  padding: 20px;
  padding-right: 50px;
  padding-left: 50px;
  width: 600px;
  border-bottom-color: $brand-primary;
  border-top-color: $brand-primary;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: inline-block;
}



@media (max-width: 560px){



  .title-content{
    padding: 20px;
    padding-right: 50px;
    padding-left: 50px;
    width: 100%;
    border-bottom-color: $brand-primary;
    border-top-color: $brand-primary;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
    display: block;
  }

  .head-title {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    display: inline-block;
    text-align: center;
  }

}

.treat-title{
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 10px;
}

a.treat-title{
  font-size: 16px;
  text-decoration: none;
}

a.treat-title:hover{
  background-color: $brand-primary;
  color: #fff;
}

@media (min-width: 768px){
  .navbar-nav > li > a {
      padding-top: 26px;
      padding-bottom: 14px;
  }

  .navbar {
    border-radius: 0;
  }

}

.btn.btn-primary.outline{
  background-color: transparent;
  color: $brand-primary;
  border-color: $brand-primary;
  border-radius: 0;
  border-style: solid;
}

a.btn.btn-primary.outline:hover{
  background-color: $brand-primary;
  color: #fff;
  border-color: $brand-primary;
  border-radius: 0;
  border-style: solid;
}
